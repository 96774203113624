import React from "react";
import { Container, Typography } from "@mui/material";

const About = () => {
  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        About Us
      </Typography>
      <Typography variant="body1" paragraph>
        Welcome to Nive's Life Style! We are dedicated to bringing you stylish and high-quality products that
        enhance your life. Our mission is to inspire confidence and elegance in every customer.
      </Typography>
    </Container>
  );
};

export default About;
