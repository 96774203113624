import { Box, createTheme, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Users from "./pages/admin/users/Users.jsx";
import Home from "./pages/site/Home";
import About from "./pages/site/About";
import Contact from "./pages/site/Contact";


import { Dashboard } from "./pages/admin/Dashboard.jsx";
import { Settings } from "./pages/admin/Settings.jsx";

import SiteLayout from "./layouts/SiteLayout.jsx";
import AdminLayout from "./layouts/AdminLayout.jsx";

function App() {
  const [theme, setTheme] = useState("light");

  const darkTheme = createTheme({
    palette: {
      mode: theme,
    },
    typography: {
      fontFamily: "Poppins",
      fontWeightLight: 400,
      fontWeightMedium: 600,
      fontWeightRegular: 500,
    },
  });

  useEffect(() => {
    setTheme("light");
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <Box bgcolor={"background.default"} color={"text.primary"}>
        <Router>
          <Routes>
            {/* Routes for the SiteLayout */}
            <Route path="/" element={<SiteLayout />}>
              <Route index element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="contact" element={<Contact />} />
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />                 
            </Route>

            {/* Routes for the AdminLayout */}
            <Route path="/admin" element={<AdminLayout />}>
              <Route index element={<Dashboard />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="users" element={<Users />} />
              <Route path="settings" element={<Settings />} />
            </Route>
          </Routes>
        </Router>
      </Box>
    </ThemeProvider>
  );
}

export default App;
