import React from "react";
import Hero from "../../components/site/Hero"
import { Typography } from "@mui/material";
// import FeaturesGrid from "../../components/site/FeaturesGrid"

const Home = () => {
  return (
    <Typography variant="div">
      <Hero />
      {/* <FeaturesGrid /> */}
    </Typography>
  );
};

export default Home;
