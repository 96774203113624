import React from "react";
import {
  Box,
  Paper,
  Typography,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const Dashboard = () => {
  return (
    <Box>
      {/* Dashboard Header */}
      <Typography variant="h4" gutterBottom>
        Admin Dashboard
      </Typography>
      <Divider sx={{ mb: 3 }} />

      {/* Metrics Grid */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <StyledPaper elevation={3}>
            <Typography variant="h6">Total Users</Typography>
            <Typography variant="h4" color="primary">
              120
            </Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledPaper elevation={3}>
            <Typography variant="h6">Active Sessions</Typography>
            <Typography variant="h4" color="secondary">
              45
            </Typography>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StyledPaper elevation={3}>
            <Typography variant="h6">Monthly Revenue</Typography>
            <Typography variant="h4" color="success.main">
              $12,345
            </Typography>
          </StyledPaper>
        </Grid>
      </Grid>

      {/* Content Sections */}
      <Grid container spacing={3} sx={{ mt: 4 }}>
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                User Activity
              </Typography>
              {/* Placeholder for chart */}
              <Box
                sx={{
                  height: 200,
                  backgroundColor: "#f0f0f0",
                  borderRadius: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2">[Chart Placeholder]</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Notifications
              </Typography>
              <Box sx={{ maxHeight: 200, overflowY: "auto" }}>
                <Typography variant="body2" gutterBottom>
                  - New user registered: John Doe
                </Typography>
                <Typography variant="body2" gutterBottom>
                  - Server maintenance scheduled at midnight
                </Typography>
                <Typography variant="body2" gutterBottom>
                  - Update your password policy
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
