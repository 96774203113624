import React from "react";
import { Box, Container, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Box sx={{ bgcolor: "primary.main", color: "white", p: 2, mt: 4 }} >
      <Container>
        <Typography variant="subtitle1" align="center">
          &copy; 2024 Nive's Life Style. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
