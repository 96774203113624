import React from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "../components/site/Header";
import Footer from "../components/site/Footer";

const SiteLayout = () => {
  return (
    <Box>
      {/* Sidebar */}
      <Header />
      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: 3,
        }}
      >
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
};

export default SiteLayout;
