import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Stack,
} from "@mui/material";
import { createUser, updateUser } from "../../../services/api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

const UserModal = ({ open, onClose, user }) => {
  const [formData, setFormData] = useState({ name: "", email: "" });

  useEffect(() => {
    if (user) {
      setFormData({ name: user.username, email: user.email });
    } else {
      setFormData({ name: "", email: "" });
    }
  }, [user]);

  const handleSubmit = async () => {
    try {
      if (user) {
        await updateUser(user.id, formData);
        alert("User updated successfully!");
      } else {
        await createUser(formData);
        alert("User created successfully!");
      }
      onClose();
    } catch (error) {
      alert("Error saving user!");
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2" marginBottom={2}>
          {user ? "Edit User" : "Add User"}
        </Typography>
        <TextField
          label="Name"
          fullWidth
          margin="normal"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        />
        <TextField
          label="Email"
          fullWidth
          margin="normal"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        />
        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          marginTop={2}
        >
          <Button variant="outlined" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {user ? "Update" : "Create"}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default UserModal;
